import type { ContentRenderingUrls } from '@elseu/sdu-titan-content-rendering';

const contentRenderingUrls: ContentRenderingUrls = {
  alertsUrl: '/notificaties',
  contentUrl: '/content',
  editionUrl: '/tijdschriften/:magazineId/:editionId',
  magazinesUrl: '/tijdschriften',
  magazineUrl: '/tijdschriften/:magazineId',
  pnNavigationUrl: '/overzicht/:slug/toc/:documentKey/:subId',
  readingListsUrl: '/leeslijsten',
  searchUrl: '/zoeken',
  newsSourceUrl: '/nieuws/:sourceId',
  newsOverviewUrl: '/nieuws',
  tariffCodeUrl: '/gebruikstarief/code',
};

const urls = {
  tariffUrl: '/gebruikstarief',
  tariffSectionUrl: '/gebruikstarief/afdeling/[sectionId]',
  tariffChapterUrl: '/gebruikstarief/afdeling/[sectionId]/hoofdstuk/[chapterId]',
  tariffCodeUrl: '/gebruikstarief/code/:codeId',
};

export { contentRenderingUrls, urls };
