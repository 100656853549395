import { config } from '@/config';

export async function loadMessages(locale: string = config.defaultLocale) {
  /** TODO: init other packages translation string by importing them from their folder */
  const { messages: readingListsMessages } = await import(
    `@elseu/sdu-titan-reading-lists-react/locales/${locale}/messages.js`
  );
  const { messages: contentRenderingMessages } = await import(
    `@elseu/sdu-titan-content-rendering/locales/${locale}/messages.js`
  );
  const { messages: pscMessages } = await import(
    `@elseu/sdu-titan-product-site-components/locales/${locale}/messages.js`
  );
  const { messages: searchMessages } = await import(
    `@elseu/sdu-titan-search/locales/${locale}/messages.js`
  );

  return {
    ...readingListsMessages,
    ...contentRenderingMessages,
    ...searchMessages,
    ...pscMessages,
  };
}
