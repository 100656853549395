/**
 * Options for generating the document URL.
 * @typedef {Object} DocumentUrlOptions
 * @property {string} itemReference - The reference to the document.
 * @property {Object} urls - An object containing base URLs.
 * @property {string} urls.tariffCodeUrl - The base URL for tariff code documents.
 * @property {string} urls.contentUrl - The base URL for content documents.
 */

/**
 * Generates the correct URL based on the itemReference.
 *
 * @param {DocumentUrlOptions} options - The options for generating the document URL.
 * @returns {string} - The URL of the document.
 */
export const getDocumentUrl = ({
  itemReference,
  urls,
}: {
  itemReference: string;
  urls: { tariffCodeUrl?: string; contentUrl: string };
}): string => {
  const isNumeric = /^\d+$/.test(itemReference);

  if (isNumeric && urls.tariffCodeUrl) {
    return urls.tariffCodeUrl;
  } else {
    return urls.contentUrl;
  }
};
