import type { NextPage, NextPageContext } from 'next';
import type { AppContext, AppInitialProps } from 'next/app';
import type App from 'next/app';
import { useMemo } from 'react';

import type { ApolloCacheControlCacheSnapshot } from './ApolloCacheControl';
import { ApolloCacheControl } from './ApolloCacheControl';

function getDisplayName(Component: React.ComponentType<React.PropsWithChildren>) {
  return Component.displayName || Component.name || 'Unknown';
}
type WithApolloContext = AppContext & NextPageContext;

export interface WithApolloProps {
  apolloCacheControlSnapshot?: ApolloCacheControlCacheSnapshot;
  apolloCacheControl?: ApolloCacheControl;
}

function withApolloServerSideRender(PageComponent: NextPage | typeof App) {
  const ApolloCacheControlContext = ApolloCacheControl.getContext();

  function WithApollo({
    apolloCacheControlSnapshot,
    apolloCacheControl,
    ...props
  }: WithApolloProps) {
    const _apolloCacheControl = useMemo<ApolloCacheControl>(
      () => apolloCacheControl || new ApolloCacheControl(),
      [apolloCacheControl],
    );
    if (apolloCacheControlSnapshot && Object.keys(apolloCacheControlSnapshot).length) {
      _apolloCacheControl.restoreSnapshot(apolloCacheControlSnapshot);
    }
    return (
      <ApolloCacheControlContext.Provider value={_apolloCacheControl}>
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        <PageComponent {...(props as any)} />
      </ApolloCacheControlContext.Provider>
    );
  }

  WithApollo.displayName = `WithApollo(${getDisplayName(PageComponent)})`;

  WithApollo.getInitialProps = async (ctx: WithApolloContext) => {
    const { AppTree } = ctx;
    const isInAppContext = Boolean(ctx.ctx);

    // Run wrapped getInitialProps methods
    let pageProps = {};
    if (PageComponent.getInitialProps) {
      pageProps = { ...pageProps, ...(await PageComponent.getInitialProps(ctx)) };
    }

    if (typeof window !== 'undefined') {
      return pageProps;
    }

    if (ctx.res && (ctx.res.headersSent || ctx.res.writableEnded)) {
      return pageProps;
    }

    const apolloCacheControl = new ApolloCacheControl();

    try {
      const { getDataFromTree } = await import('@apollo/client/react/ssr');
      // Since AppComponents and PageComponents have different context types
      // we need to modify their props a little.
      let props;
      if (isInAppContext) {
        props = { ...pageProps, apolloCacheControl };
      } else {
        props = { pageProps: { ...pageProps, apolloCacheControl } };
      }
      /**
       * Render the page through Apollo's `getDataFromTree` so the cache is populated.
       * Unfortunately this renders the page twice per request... There may be a way around doing this, but I haven't quite ironed that out yet.
       *
       * TODO: keep an eye on this pr and latest React 18 version for concurrent rendering warning/error in console
       * @see https://github.com/facebook/react/pull/22797
       */
      await getDataFromTree(<AppTree {...(props as AppInitialProps)} />);
    } catch (error) {
      // Prevent Apollo Client GraphQL errors from crashing SSR.
      // Handle them in components via the data.error prop:
      // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
      apolloCacheControl.seal();
      console.error('Error while running `getDataFromTree` or `getPromiseDataFromTree`', error);
    }

    /**
     * Extract the cache to pass along to the client so the queries are "hydrated" and don't need to actually request the data again!
     */
    const props = {
      ...pageProps,
      apolloCacheControlSnapshot: apolloCacheControl.getSnapshot(),
    };

    apolloCacheControl.seal();

    return props;
  };

  return WithApollo;
}

export { withApolloServerSideRender };
