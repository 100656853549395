import type { NextConfig } from '@/config';

export function getDefaultSeo(config: NextConfig) {
  return {
    dangerouslySetAllPagesToNoFollow: config.allowRobots !== 'true',
    dangerouslySetAllPagesToNoIndex: config.allowRobots !== 'true',
    titleTemplate: `%s | ${config.brandName}`,
    openGraph: {
      images: [
        {
          url: `${config.siteUrl}/banner1200.jpg`,
          width: 1200,
          height: 630,
          alt: 'Sdu',
        },
      ],
      type: 'website',
      locale: config.locale,
      site_name: config.brandName,
    },
  };
}
